<template>
  <div class="layout-center">
    <Module :fluid="isMobile" @login="handlerLogin"></Module>
  </div>
</template>

<script>
import Module from "./Module";

export default {
  name: "Login",
  components: {
    Module,
  },
  computed: {
    isMobile() {
      return this.$mq === "sm";
    },
  },
  methods: {
    handlerLogin() {
      this.$router.push("/painel/atletas");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: #1f213179;
}
</style>
